<template>
  <div>
    <reservation class="center" style="max-width: 800px; width: 94%" />

    <div style="padding: 10px">
      <slideshow style="max-width: 800px" class="center"></slideshow>
    </div>

    <div class="grid-container">
      <div class="icon-container">
        <div class="row" v-for="(row, index) in rows" :key="index">
          <div class="item" v-for="item in row" :key="item.text">
            <icon-with-text :text="item.text" :icon="item.icon" :style="item.style" />
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="grid-container">
      <icon-with-text
        style="background: #e88e82"
        text="NO PETS ALLOWED"
        :icon="require('@/assets/no_pets.svg')"
      />
    </div> -->

    <div class="grid-container">
      <div class="main-content about-section">
        <h2>About</h2>
        <div style="padding-top: 10px"></div>
        <p>
          Welcome to the Mio Motel, where comfort meets affordability. Our cozy
          rooms, friendly service, and convenient location ensure a pleasant
          stay.
        </p>
        <div style="padding-top: 10px"></div>
        <p>
          Located on M-33 next to McDonald's and just a quarter mile north of
          the M-33 and M-72 intersection, the Mio Motel provides easy access to all
          that northern Michigan has to offer.
        </p>
        <div style="padding-top: 10px"></div>
        <p>
          As a family-owned business, we take pride in exceptional customer
          service and satisfaction. Our professional staff is here to assist
          with any questions about our services or local outdoor recreational
          activities.
        </p>
      </div>
    </div>

    <div class="grid-container">
      <div class="main-content about-section">
        <h2>Directions</h2>
        <p>We are located at 415 N. Morenci Ave., Mio, Michigan.</p>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2774.9257527312457!2d-84.13297998451799!3d44.65573027909971!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88208e447928b731%3A0xc497f9b5cd225766!2sMio+Motel!5e1!3m2!1sen!2sus!4v1535063461551"
          width="90%"
          height="450px"
          frameborder="0"
          style="border: 0"
          allowfullscreen=""
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import Slideshow from "@/components/Slideshow.vue";
import Reservation from "../components/Reservation.vue";
import IconWithText from "../components/IconWithText.vue";

export default {
  components: {
    Slideshow,
    Reservation,
    IconWithText,
  },
  data() {
    return {
      rows: [
        [
          {
            text: "FREE 300 Mb/S INTERNET",
            icon: require("@/assets/wifi.svg"),
          },
          { text: "FRIDGES", icon: require("@/assets/fridge.svg") },
          { text: "AMPLE PARKING", icon: require("@/assets/car.svg") },
        ],
        [
          { text: "QUEEN BEDS", icon: require("@/assets/bed.svg") },
          { text: "MICROWAVES", icon: require("@/assets/microwave.svg") },
          {
            text: "PICNIC TABLES & BBQ GRILLS",
            icon: require("@/assets/grill.svg"),
          },
        ],
        [
          {
            text: "FRIENDLY STAFF",
            icon: require("@/assets/mio_motel_logo.svg"),
          },
          { text: "SPECTRUM CABLE TV", icon: require("@/assets/tv.svg") },
          { text: "CAMPFIRE & WOOD", icon: require("@/assets/fire.svg") },
        ],
        [{ text: "AIRCONDITIONING", icon: require("@/assets/air.svg") },
          { text: "FREE IN-ROOM COFFEE", icon: require("@/assets/coffee.svg") },
          { text: "NO PETS ALLOWED", icon: require("@/assets/no_pets.svg"), style: "background: #e88e82" },
        ]
      ],
    };
  },
};
</script>


    <style scoped>
.header {
  background-color: #333;
  color: #fff;
  padding: 20px;
  /* width: 100%; */
  text-align: center;
  /* overflow: hidden; */
}

.icon-container {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adjust gap between rows as needed */
}
.row {
  display: flex;
  justify-content: space-between;
  gap: 10px; /* Adjust gap between items as needed */
}
.item {
  flex: 1;
}
</style>
