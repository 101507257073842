<template>
  <header class="header">
    <div class="logo">
      <img :src="logoSrc" alt="Logo" class="logo-image"  />
    </div>
    <nav class="tabs">
      <ul class="tabs-list" v-show="!isMobileMenuOpen">
        <li v-for="tab in tabs" :key="tab.name" @click="selectTab(tab)" :class="{ active: currentTab === tab }">
          {{ tab.name }}
        </li>
      </ul>
      <button class="menu-toggle" @click="toggleMobileMenu">
        <span class="bar"></span>
        <span class="bar"></span>
        <span class="bar"></span>
      </button>
      <transition name="fade">
      <div class="mobile-menu" v-show="isMobileMenuOpen">
        <ul>
          <li v-for="tab in tabs" :key="tab.name" @click="selectTab(tab)" :class="{ active: currentTab === tab }">
            {{ tab.name }}
          </li>
        </ul>
      </div>
      </transition>
    </nav>
  </header>
</template>

<script>

import logo from '@/assets/mio_motel_logo.svg';

export default {
  name: 'Header',
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    initialTab: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      currentTab: this.initialTab,
      logoSrc: logo,
      isMobileMenuOpen: false,
    };
  },
  methods: {
    selectTab(tab) {
      this.currentTab = tab;
      this.$router.push(tab.location);
      this.isMobileMenuOpen = false; // Close mobile menu after selection
    },
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
  },
};
</script>

<style scoped>
.header {
    
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
  position: relative;
}

.logo {
  /* flex: 1; */
  padding-right: 1rem;
}

.logo-image {
  max-height: 50px;
}

.tabs {
  flex: 2;
  display: flex;
  align-items: center;
}

.tabs-list {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.tabs li {
  margin: 0 1rem;
  cursor: pointer;
}

.tabs li.active {
  font-weight: bold;
  border-bottom: 2px solid #007bff;
}

/* Styles for hamburger menu button */
.menu-toggle {

  display: none; /* Hidden by default, shown on smaller screens */
  flex-direction: column;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  position: absolute;
  right: 1rem; /* Aligns the button to the right */
  top: 50%;
  transform: translateY(-50%); /* Centers the button vertically */
  z-index: 1001;
}

.menu-toggle .bar {
  background-color: #333;
  height: 3px;
  width: 25px;
  margin: 3px 0;
  transition: 0.3s;
}

/* Mobile menu styles */
.mobile-menu {
  transition: max-height 0.5s fade;
  display: none; /* Hidden by default, shown on smaller screens */
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  width: 100%;
  z-index: 1000;
}

.mobile-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.mobile-menu li {
  padding: 1rem;
  cursor: pointer;
}

.mobile-menu li.active {
        transition: max-height 0.5s ease-out;
  font-weight: bold;
  border-bottom: 2px solid #007bff;
}

/* Responsive styles */
@media (max-width: 550px) {
  .tabs-list {
    display: none;
  }

  .menu-toggle {
    display: flex; /* Show the hamburger menu */
  }

  .mobile-menu {
    display: block; /* Show the mobile menu */
  }
}

@media (min-width: 550px) {
  .menu-toggle {
    display: none; /* Hide the hamburger menu on larger screens */
  }

  .mobile-menu {
    display: none; /* Hide the mobile menu on larger screens */
  }
}
</style>