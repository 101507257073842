<template>
  <Header
    :tabs="[
      { name: 'Home', location: '/' },
      { name: 'Rooms', location: '/rooms' },
      { name: 'Things to do', location: '/things-to-do' },
    ]"
  />
  <div style="padding-top: 10px"></div>
  <router-view />
  <div style="padding-top: 200px"></div>
  <Footer></Footer>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from './components/Footer.vue';

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.main-content {
  padding: 20px;
  background: #d2b48c44;
  border-radius: 10px;
}

.grid-container {
  display: grid;
  padding: 10px;
  gap: 16px;
  max-width: 800px;
  margin: 0 auto;
}

.about-section {
  font-family: "Arial", sans-serif; /* Clean and modern sans-serif font */
  color: #333; /* Dark grey color for better readability */
  line-height: 1.6; /* Spacing between lines for improved readability */
}

/* Heading styles */
.about-section h1,
.about-section h2,
.about-section h3 {
  font-family: "Georgia", serif; /* Elegant serif font for headings */
  color: #222; /* Slightly darker grey for emphasis */
  margin-bottom: 0.5em; /* Space below headings */
}

/* Paragraph styles */
.about-section p {
  font-size: 16px; /* Standard font size for readability */
  margin-bottom: 1em; /* Space between paragraphs */
}

/* Emphasized text styles */
.about-section em {
  font-style: italic; /* Italicize emphasized text */
  color: #555; /* Slightly lighter grey for emphasis */
}

/* Strong text styles */
.about-section strong {
  font-weight: bold; /* Bold text for strong emphasis */
  color: #000; /* Black color for high contrast */
}

.overline {
  /* text-align: left; */
  font-family: "Plus_Jakarta_Sans", "Roboto", -apple-system, "Segoe UI",
    sans-serif;
  font-weight: 200;
  font-size: clamp(0.6rem, calc(0.31rem + 1.97vw), 0.875rem);
  /* font-size: var(--fs-sm-200); */
  letter-spacing: -0.01em;
  /* text-transform: uppercase; */
  /* margin-bottom: 0.25rem !important;
  margin-top: 0.25rem !important; */
  color: #222;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* #nav {
  padding: 30px;
} */

/* :root {
  overflow-x: hidden;
} */

* {
  margin: 0;
  padding: 0;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

::after {
  background: #000;
}

.center {
  margin: auto;
  width: 50%;
}
</style>
