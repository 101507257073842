<template>
  <div>
    <!-- Your main content here -->

    <transition name="fade">
      <footer v-if="isVisible" class="footer">
        <p style="font-size: 13px">415 N. Morenci Ave., P.O Box 251</p>
        <p style="font-size: 13px">Mio, Michigan 48647-9473</p>
        <div style="padding-bottom: 3px"></div>
        <p style="font-size: 10px">
          &copy; 2023 Mio Motel LLC. All rights reserved.
        </p>
        <p style="font-size: 10px">
          Website designed and created by Attar Software LLC
        </p>

      </footer>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    handleScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      const threshold = document.documentElement.offsetHeight - 200; // 100px from the bottom

      const isShortPage = document.documentElement.offsetHeight <= window.innerHeight;

      this.isVisible = scrollPosition > threshold;

      if (isShortPage) {
        this.isVisible = true;
      }
      
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll(); 

  },
watch:{
   '$route' (newRoute, lastRoute){
this.handleScroll();
   },
},

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  color: #222;
  text-align: center;
  text-align: center;
  padding: 10px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
